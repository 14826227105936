import React, { useContext, useEffect } from 'react'
// Context
import ObjectContext from 'context/ObjectContext'
// Babylon
import * as BabylonViewer from '@babylonjs/viewer'
// Components
import { ButtonAR } from 'components/ButtonAR'
import QRCode from 'qrcode.react'
// Logo
import Logo from 'assets/img/logo.svg'

export const Babylon = () => {
  const context = useContext(ObjectContext)
  const { language } = context

  const getMobileOperatingSystem = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera
    if (/android/i.test(userAgent)) {
      return 'Android'
    }
    if (
      (/iPad|iPhone|iPod/.test(userAgent) ||
        (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) &&
      !window.MSStream
    ) {
      return 'iOS'
    }
    return 'unknown'
  }

  useEffect(() => {
    if (context.selectedObject) {
      context.setLoadingObject(true)
      BabylonViewer.viewerManager
        .getViewerPromiseById('babylon-viewer')
        .then(function (viewer) {
          if (viewer?.sceneManager?.scene) {
            try {
              viewer.loadModel({
                title: context.selectedObject.name,
                subtitle: context.selectedObject.subtitle,
                thumbnail: context.selectedObject.previewImage,
                url: context.selectedObject.ar.glb
              })
            } catch (error) {
              console.log(error)
            }
          } else {
            viewer.onModelLoadedObservable.add(function () {
              context.setLoadingObject(false)
            })
            viewer.onEngineInitObservable.add(function (scene) {
              try {
                viewer.loadModel({
                  title: context.selectedObject.name,
                  subtitle: context.selectedObject.subtitle,
                  thumbnail: context.selectedObject.previewImage,
                  url: context.selectedObject.ar.glb
                })
              } catch (error) {
                console.log(error)
              }
            })
          }
        })
    }
  }, [context.selectedObject])

  return (
    <div className='flex-1 h-full relative bg-gray-400'>
      <img
        className='absolute z-10 right-8 top-4 w-32 xl:w-52'
        alt='logo'
        src={Logo}
      />
      <babylon id='babylon-viewer'>
        <templates>
          <nav-bar html='<div/>' />
          <loading-screen location='#loading-screen'>
            <params background-color='#a3a3a3' />
          </loading-screen>
        </templates>
      </babylon>
      <div className='absolute bottom-0 w-1/2 left-0 right-0 ml-auto mr-auto z-20'>
        <ButtonAR objectInfo={context?.selectedObject} />
      </div>
      {getMobileOperatingSystem() === 'unknown' && context?.selectedObject && (
        <div className='absolute w-56 text-center flex flex-col justify-center items-center bottom-10 right-4 z-10'>
          <h3 className='mt-0 mb-2 text-sm uppercase tracking-widest text-gray-600'>
            <b>
              {language === 'en' && 'Preview object in AR'}
              {language === 'es' && 'Ver este objeto en AR'}
              {language === 'it' && 'Vedi questo oggetto in AR'}
            </b>
          </h3>
          <h6 className='mt-0 mb-6 text-xs text-gray-500'>
            {language === 'en' &&
              'iOS 13+, iPadOS 13+ o Android con ARCore 1.9+ required'}
            {language === 'es' &&
              'iOS 13+, iPadOS 13+ o Android con ARCore 1.9+ requirido'}
            {language === 'it' &&
              'Richiede iOS 13+, iPadOS 13+ o Android con ARCore 1.9+'}
          </h6>
          <QRCode
            fgColor='#333'
            value={`https://${window.location.hostname}/openAR/${context?.activeSection}/${context?.selectedObject?.id}`}
          />
        </div>
      )}
    </div>
  )
}
