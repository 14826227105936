import React, { useContext } from 'react'
// Translation
import { useTranslation } from 'react-i18next'
// React-Select
import Select from 'react-select'
// Context
import ObjectContext from 'context/ObjectContext'

const LanguageSelector = () => {
  const context = useContext(ObjectContext)
  const languageOptions = [
    { value: 'es', label: 'ES' },
    { value: 'en', label: 'EN' },
    { value: 'it', label: 'IT' }
  ]
  const { language, setLanguage } = context

  return (
    <div className='mt-auto mb-2 px-1 mx-1'>
      <p className='text-primary-dark mb-2 text-center'>
        {language === 'en' && ('Language')}
        {language === 'es' && ('Idioma')}
        {language === 'it' && ('Idioma')}
      </p>
      <Select
        className='basic-single'
        classNamePrefix='select'
        value={languageOptions.filter((option) => option.value === language)}
        name='language'
        options={languageOptions}
        menuPlacement='top'
        onChange={(e) => {
          setLanguage(e.value)
        }}
      />
    </div>
  )
}

export default LanguageSelector
