import React, { useState } from 'react'

export const FadeInImage = ({ src }) => {
  const [loaded, setLoaded] = useState(false)

  return (
    <img
      onLoad={() => setLoaded(true)}
      className={`transition duration-500 ease-in-out ${loaded ? 'opacity-100' : 'opacity-0'} absolute top-0 object-contain`}
      src={src}
      alt='object'
    />
  )
}
