import * as React from 'react'

export const SinkIcon = (props) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' {...props}>
      <path
        d='M271 197.081h-30V62.769C241 28.158 269.158 0 303.769 0s62.77 28.158 62.77 62.769v25.108h-30V62.769c0-18.069-14.7-32.769-32.77-32.769C285.7 30 271 44.7 271 62.769v134.312z'
        fill='#c7c5cc'
      />
      <path fill='#0082d1' d='M198.13 391.99h115.74V512H198.13z' />
      <path fill='#005c95' d='M256 391.99h57.87V512H256z' />
      <path
        d='M459.24 257.04v15c0 82.69-67.27 149.95-149.95 149.95H202.71c-82.68 0-149.95-67.26-149.95-149.95v-15h406.48z'
        fill='#a2d8f5'
      />
      <path
        d='M459.24 257.04v15c0 82.69-67.27 149.95-149.95 149.95H256V257.04h203.24z'
        fill='#7fc7f0'
      />
      <path
        fill='#c7c5cc'
        d='M86.007 161.733h22.573v48.8h30v-48.8h22.37v-30H86.007z'
      />
      <path
        fill='#adacb2'
        d='M425.993 131.733H351.05v30h22.572v48.8h30v-48.8h22.371z'
      />
      <path fill='#0082d1' d='M35.26 195.53h441.48v91.51H35.26z' />
      <path fill='#005c95' d='M256 195.53h220.74v91.51H256z' />
    </svg>
  )
}
