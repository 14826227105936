export const OBJECTS = {
  sinks: [
    {
      id: 'lavabo1',
      name: 'LABELLUM_S',
      subtitle: 'Statuarietto',
      previewImage: 'https://firebasestorage.googleapis.com/v0/b/kaleydos-ar.appspot.com/o/sinks%2Flavabo1%2FLavabo_1%20(1)_200x200.png?alt=media&token=11b0ff0d-9611-456f-b7a6-14da3613aba3',
      ar: {
        glb: 'https://firebasestorage.googleapis.com/v0/b/kaleydos-ar.appspot.com/o/sinks%2Flavabo1%2FLavabo_1.glb?alt=media&token=ee9c9779-1184-465c-8ecc-1c3e68ced670',
        usdz: 'https://firebasestorage.googleapis.com/v0/b/kaleydos-ar.appspot.com/o/sinks%2Flavabo1%2F98d8f45a-7390-413b-9390-afc0eb092f3b.usdz?alt=media&token=6d55c5d4-39ee-4bb2-ae3a-5da32420cee1'
      },
      techSpecs: 'https://firebasestorage.googleapis.com/v0/b/kaleydos-ar.appspot.com/o/sinks%2Flavabo1%2FLAVABO_1.pdf?alt=media&token=02c5e68e-1da2-4815-9e4c-b06cbaac5527',
      gallery: 'https://firebasestorage.googleapis.com/v0/b/kaleydos-ar.appspot.com/o/sinks%2Flavabo1%2FLavaboSospeso_Statuarietto_PRV3.jpg?alt=media&token=3312c63a-c1dd-4532-adf3-dcee3c4c805f'
    },
    {
      id: 'lavabo2',
      name: 'LABELLUM_M',
      subtitle: 'Statuarietto',
      previewImage: 'https://firebasestorage.googleapis.com/v0/b/kaleydos-ar.appspot.com/o/sinks%2Flavabo2%2FLavabo_2_200x200.png?alt=media&token=023797a7-5477-443a-b16c-ee3973c2d070',
      ar: {
        glb: 'https://firebasestorage.googleapis.com/v0/b/kaleydos-ar.appspot.com/o/sinks%2Flavabo2%2FLavabo_2.glb?alt=media&token=9b62c856-e667-44e6-a25a-d3a7fb200cd9',
        usdz: 'https://firebasestorage.googleapis.com/v0/b/kaleydos-ar.appspot.com/o/sinks%2Flavabo2%2Ff1543fa5-3ec6-4021-96ce-3d95e61fe7a2.usdz?alt=media&token=cb786ffe-9429-4edf-bc38-4a48b725a8d7'
      },
      techSpecs: 'https://firebasestorage.googleapis.com/v0/b/kaleydos-ar.appspot.com/o/sinks%2Flavabo2%2FLAVABO_2.pdf?alt=media&token=cc610ce0-c591-4cfc-bd49-21320f454764',
      gallery: 'https://firebasestorage.googleapis.com/v0/b/kaleydos-ar.appspot.com/o/sinks%2Flavabo2%2FLavabo_2_Statuarietto_PRV04.jpg?alt=media&token=4ef3c90c-e8af-447f-bd17-e94b23024ad6'
    },
    {
      id: 'lavabo3',
      name: 'LABELLUM_L',
      subtitle: 'Nero Greco',
      previewImage: 'https://firebasestorage.googleapis.com/v0/b/kaleydos-ar.appspot.com/o/sinks%2Flavabo3%2FLavabo_3_200x200.png?alt=media&token=aea0278a-2a2b-48b7-854b-322429e10d3c',
      ar: {
        glb: 'https://firebasestorage.googleapis.com/v0/b/kaleydos-ar.appspot.com/o/sinks%2Flavabo3%2FLavabo_3.glb?alt=media&token=03bb3bf0-ab1f-4f2b-b2c8-e262939020c5',
        usdz: 'https://firebasestorage.googleapis.com/v0/b/kaleydos-ar.appspot.com/o/sinks%2Flavabo3%2F562dd486-50d9-4f2d-94de-fa1a44a1fb72.usdz?alt=media&token=d72cf4fd-5334-4390-9c22-050623a4ec42'
      },
      techSpecs: 'https://firebasestorage.googleapis.com/v0/b/kaleydos-ar.appspot.com/o/sinks%2Flavabo3%2FLAVABO_3.pdf?alt=media&token=edd5866a-d224-450f-95ca-781750a14a9b',
      gallery: 'https://firebasestorage.googleapis.com/v0/b/kaleydos-ar.appspot.com/o/sinks%2Flavabo3%2FLavabo_3_PIETRA%20PIASENTINA%20GRIGIO_PRV04.jpg?alt=media&token=e0cc8d52-4338-4908-8f3a-8d8a38baed7b'
    },
    {
      id: 'lavabo3Variante',
      name: 'LABELLUM_L',
      subtitle: 'Pietra Piasentina',
      previewImage: 'https://firebasestorage.googleapis.com/v0/b/kaleydos-ar.appspot.com/o/sinks%2Flavabo3Variante%2FLavabo_3_Variante_200x200.png?alt=media&token=432a060b-5f9e-4300-a056-7eeefd7bd796',
      ar: {
        glb: 'https://firebasestorage.googleapis.com/v0/b/kaleydos-ar.appspot.com/o/sinks%2Flavabo3Variante%2FLavabo_3_Variante.glb?alt=media&token=faacffaf-524d-4720-967e-3fe0245f872f',
        usdz: 'https://firebasestorage.googleapis.com/v0/b/kaleydos-ar.appspot.com/o/sinks%2Flavabo3Variante%2F7077f491-7bf9-4233-83f6-fa7e80af931f.usdz?alt=media&token=db7ee40f-876d-4608-a3cf-2c4ab850b1f9'
      },
      techSpecs: 'https://firebasestorage.googleapis.com/v0/b/kaleydos-ar.appspot.com/o/sinks%2Flavabo3Variante%2FLAVABO_3variante.pdf?alt=media&token=3db0b150-84e6-46a5-905f-318b01278197',
      gallery: 'https://firebasestorage.googleapis.com/v0/b/kaleydos-ar.appspot.com/o/sinks%2Flavabo3Variante%2FLavabo_3_Variante_PIETRA%20PIASENTINA%20GRIGIO_Previo03.jpg?alt=media&token=ca39299a-b9cb-4def-8047-8905d5d32ce6'
    },
    {
      id: 'lavabo4',
      name: 'VIRTUS',
      subtitle: 'Pietra Piasentina',
      previewImage: 'https://firebasestorage.googleapis.com/v0/b/kaleydos-ar.appspot.com/o/sinks%2Flavabo4%2FLavabo_4_200x200.png?alt=media&token=0012eee5-6c30-40ab-9fbe-c2800c3c993e',
      ar: {
        glb: 'https://firebasestorage.googleapis.com/v0/b/kaleydos-ar.appspot.com/o/sinks%2Flavabo4%2FLavabo_4.glb?alt=media&token=ce6c376c-1b6c-4715-95ba-a0021fab8776',
        usdz: 'https://firebasestorage.googleapis.com/v0/b/kaleydos-ar.appspot.com/o/sinks%2Flavabo4%2F98dd793f-a29e-44e8-81b2-8079b82c91b0.usdz?alt=media&token=515110e6-2718-4cd3-a5df-88bdd3b374ba'
      },
      techSpecs: 'https://firebasestorage.googleapis.com/v0/b/kaleydos-ar.appspot.com/o/sinks%2Flavabo4%2FLAVABO_4.pdf?alt=media&token=77905525-3631-4e78-a6b5-bc51b8e1a7d9',
      gallery: 'https://firebasestorage.googleapis.com/v0/b/kaleydos-ar.appspot.com/o/sinks%2Flavabo4%2FLavabo_4_PIETRA%20PIASENTINA%20GRIGIO_PRV04.jpg?alt=media&token=d842d72d-7526-40f9-8b37-2e7dce36a87e'
    }
  ],
  tables: [
    {
      id: 'tavolo01',
      name: 'LUNARIS_S',
      subtitle: 'Noir Desir',
      previewImage: 'https://firebasestorage.googleapis.com/v0/b/kaleydos-ar.appspot.com/o/tavolo01%2Ftavolo1_200x200.png?alt=media&token=850d7f77-acbd-4b02-80bf-ffd0433087e6',
      ar: {
        glb: 'https://firebasestorage.googleapis.com/v0/b/kaleydos-ar.appspot.com/o/tavolo01%2FTavolo_1.glb?alt=media&token=8c5fd498-0d19-4873-bcf9-539962a16508',
        usdz: 'https://firebasestorage.googleapis.com/v0/b/kaleydos-ar.appspot.com/o/tavolo01%2F51c8ca60-fdaf-4df3-ae7f-01c04b84cee4.usdz?alt=media&token=50ef654f-6dae-497b-a6b4-6cbaeb365e5f'
      },
      techSpecs: 'https://firebasestorage.googleapis.com/v0/b/kaleydos-ar.appspot.com/o/tavolo01%2FTAVOLO_1.pdf?alt=media&token=199674f0-3981-493c-8d43-1d928c95a949',
      gallery:
        'https://firebasestorage.googleapis.com/v0/b/kaleydos-ar.appspot.com/o/tavolo01%2FTavolo_1_NoirDesir_Previa_01.jpg?alt=media&token=7008dd54-2699-4297-9870-c88e385e23d8'
    },
    {
      id: 'tavolo02',
      name: 'LUNARIS_M',
      subtitle: 'Noir Desir',
      previewImage: 'https://firebasestorage.googleapis.com/v0/b/kaleydos-ar.appspot.com/o/tavolo02%2FTavolo_2_200x200.png?alt=media&token=a98a1bff-45b4-428a-8674-1440fa045588',
      ar: {
        glb: 'https://firebasestorage.googleapis.com/v0/b/kaleydos-ar.appspot.com/o/tavolo02%2FTavolo_2.glb?alt=media&token=3d2c4f57-3a22-4853-a5b2-6c6a57d85f50',
        usdz: 'https://firebasestorage.googleapis.com/v0/b/kaleydos-ar.appspot.com/o/tavolo02%2F1385a07e-8191-4826-a53f-d5ea33213bb7.usdz?alt=media&token=9ae57a03-b515-44c9-b2bc-4553f712037d'
      },
      techSpecs: 'https://firebasestorage.googleapis.com/v0/b/kaleydos-ar.appspot.com/o/tavolo02%2FTAVOLO_2.pdf?alt=media&token=aa1c7dd9-7315-400d-8964-796caafeda16',
      gallery: 'https://firebasestorage.googleapis.com/v0/b/kaleydos-ar.appspot.com/o/tavolo02%2FTavolo_2_NoirDesir_Previa_01.jpg?alt=media&token=e484630e-b100-4e87-a8c9-c9909ed3608a'
    },
    {
      id: 'tavolo03',
      name: 'LUNARIS_L',
      subtitle: 'Noir Desir',
      previewImage: 'https://firebasestorage.googleapis.com/v0/b/kaleydos-ar.appspot.com/o/tavolo03%2FTavolo_3_200x200.png?alt=media&token=5ac6dd7f-c50c-407d-875b-6ffcc63f4e6f',
      ar: {
        glb: 'https://firebasestorage.googleapis.com/v0/b/kaleydos-ar.appspot.com/o/tavolo03%2FTavolo_3.glb?alt=media&token=c7a61988-bd57-41e4-9c7f-9282b2a3614e',
        usdz: 'https://firebasestorage.googleapis.com/v0/b/kaleydos-ar.appspot.com/o/tavolo03%2F94550f32-28de-4da4-b473-a87da8f03d95.usdz?alt=media&token=c5eb74ba-1646-4b61-8f3c-d105e3719c79'
      },
      techSpecs: 'https://firebasestorage.googleapis.com/v0/b/kaleydos-ar.appspot.com/o/tavolo03%2FTAVOLO_3.pdf?alt=media&token=61ee3f34-88bf-4b5c-ad3e-102a0c4332ba',
      gallery: 'https://firebasestorage.googleapis.com/v0/b/kaleydos-ar.appspot.com/o/tavolo03%2FTavolo_3_Previo02.jpg?alt=media&token=373920eb-d42d-4ec6-a7d3-b0c4183f0d4f'
    },
    {
      id: 'tavolo04',
      name: 'LUNARIS_XL',
      subtitle: 'Noir Desir',
      previewImage: 'https://firebasestorage.googleapis.com/v0/b/kaleydos-ar.appspot.com/o/tavolo04%2FTavolo_4_200x200.png?alt=media&token=0951da1f-675a-4631-b177-ab0ab3537e9d',
      ar: {
        glb: 'https://firebasestorage.googleapis.com/v0/b/kaleydos-ar.appspot.com/o/tavolo04%2FTavolo_4.glb?alt=media&token=dce123f5-acf5-4ace-ac9f-b9642823d6f9',
        usdz: 'https://firebasestorage.googleapis.com/v0/b/kaleydos-ar.appspot.com/o/tavolo04%2Fcaeaa5e8-de2a-47c4-91db-fb703c971c85.usdz?alt=media&token=a26eb4c3-6db9-4223-96b3-6ada875af97b'
      },
      techSpecs: 'https://firebasestorage.googleapis.com/v0/b/kaleydos-ar.appspot.com/o/tavolo04%2FTAVOLO_4.pdf?alt=media&token=5cb7fed5-3dd9-4c85-84f0-7508e0fa133a',
      gallery:
        'https://firebasestorage.googleapis.com/v0/b/kaleydos-ar.appspot.com/o/tavolo04%2FKaleydo_Mesa_04_Previo03.jpeg?alt=media&token=8fe1eb49-1918-4f52-8409-44a25c7f9ebe'
    },
    {
      id: 'tavolo05',
      name: 'MAIOR',
      subtitle: 'Invisible White',
      previewImage: 'https://firebasestorage.googleapis.com/v0/b/kaleydos-ar.appspot.com/o/tavolo05%2FTavolo_5_200x200.png?alt=media&token=139a9678-699c-46f5-ab49-1315074805aa',
      ar: {
        glb: 'https://firebasestorage.googleapis.com/v0/b/kaleydos-ar.appspot.com/o/tavolo05%2FTavolo_5.glb?alt=media&token=3ef5f8a1-cb11-4e33-addf-63b16e3320e4',
        usdz: 'https://firebasestorage.googleapis.com/v0/b/kaleydos-ar.appspot.com/o/tavolo05%2F95508748-1e14-43c1-9348-6c5c783eb274.usdz?alt=media&token=3d167a74-00f2-45fa-b55e-a906e642bd24'
      },
      techSpecs: 'https://firebasestorage.googleapis.com/v0/b/kaleydos-ar.appspot.com/o/tavolo05%2FTAVOLO_5.pdf?alt=media&token=968571d8-8919-41ff-87df-01f62637037e',
      gallery: 'https://firebasestorage.googleapis.com/v0/b/kaleydos-ar.appspot.com/o/tavolo05%2FTavolo_5_Previo01.jpg?alt=media&token=b7ef632f-57ca-401a-9bbb-b9a6b5c38307'
    },
    {
      id: 'tavolo06',
      name: 'MINOR',
      subtitle: 'Invisible White',
      previewImage: 'https://firebasestorage.googleapis.com/v0/b/kaleydos-ar.appspot.com/o/tavolo06%2FTavolo_6_200x200.png?alt=media&token=4572d83f-c68e-4bfc-81aa-e4fad6dca679',
      ar: {
        glb: 'https://firebasestorage.googleapis.com/v0/b/kaleydos-ar.appspot.com/o/tavolo06%2FTavolo_6.glb?alt=media&token=e3b87624-b966-4189-96fb-e93f6b59e535',
        usdz: 'https://firebasestorage.googleapis.com/v0/b/kaleydos-ar.appspot.com/o/tavolo06%2F126d731f-b155-44e9-815c-69ace7ec9d40.usdz?alt=media&token=4fbb7cda-d4a6-4b38-b4ce-81121052f1eb'
      },
      techSpecs: 'https://firebasestorage.googleapis.com/v0/b/kaleydos-ar.appspot.com/o/tavolo06%2FTAVOLO_6.pdf?alt=media&token=b80c79bf-4baf-4927-8fa1-2ef838dafbf1',
      gallery: 'https://firebasestorage.googleapis.com/v0/b/kaleydos-ar.appspot.com/o/tavolo06%2FTavolo_6_Previo01.jpg?alt=media&token=80486ac5-f687-47c0-9171-3a0b61a2b20d'
    },
    {
      id: 'tavoloContenitore1',
      name: 'CONVIVIO',
      subtitle: 'Nero Greco',
      previewImage: 'https://firebasestorage.googleapis.com/v0/b/kaleydos-ar.appspot.com/o/tavoloContenitore1%2FTavolo_contenitore_1_200x200.png?alt=media&token=e2725cfb-e8b1-4c5f-800e-e305e3027a24',
      ar: {
        glb: 'https://firebasestorage.googleapis.com/v0/b/kaleydos-ar.appspot.com/o/tavoloContenitore1%2FTavolo_contenitore_1.glb?alt=media&token=9d031049-f506-490b-9ab3-9b8eb24acb64',
        usdz: 'https://firebasestorage.googleapis.com/v0/b/kaleydos-ar.appspot.com/o/tavoloContenitore1%2F7f1b9a5c-d988-4123-923b-41b68c4f5534.usdz?alt=media&token=c267e05e-70b6-4e03-80e4-1260a9ae4582'
      },
      techSpecs: 'https://firebasestorage.googleapis.com/v0/b/kaleydos-ar.appspot.com/o/tavoloContenitore1%2FTavolo_contenitore_1.pdf?alt=media&token=e0bcb388-9ea5-4348-87e1-56cccb42a35f',
      gallery: 'https://firebasestorage.googleapis.com/v0/b/kaleydos-ar.appspot.com/o/tavoloContenitore1%2FTavolo_contenitore_1_Previo01.jpg?alt=media&token=cfa9f114-1436-45e2-a050-fa4b38680660'
    },
    {
      id: 'tavoloContenitore2',
      name: 'MAGNUS',
      subtitle: 'Neoir Desir',
      previewImage: 'https://firebasestorage.googleapis.com/v0/b/kaleydos-ar.appspot.com/o/tavoloContenitore2%2FTavolo_contenitore_2_200x200.png?alt=media&token=953c1c52-4d5c-4468-ab11-ff99982c5ada',
      ar: {
        glb: 'https://firebasestorage.googleapis.com/v0/b/kaleydos-ar.appspot.com/o/tavoloContenitore2%2FTavolo_contenitore_2.glb?alt=media&token=3d9a733a-fab3-40c2-8a5c-9986cba3a35e',
        usdz: 'https://firebasestorage.googleapis.com/v0/b/kaleydos-ar.appspot.com/o/tavoloContenitore2%2F49188f9a-3e1e-48d3-be92-531f13bb3f43.usdz?alt=media&token=60d4ddca-0358-4716-8033-50dcd444c8e0'
      },
      techSpecs: 'https://firebasestorage.googleapis.com/v0/b/kaleydos-ar.appspot.com/o/tavoloContenitore2%2FTavolo_contenitore_2.pdf?alt=media&token=d3360099-b460-496f-999a-0cacf4ce2e05',
      gallery: 'https://firebasestorage.googleapis.com/v0/b/kaleydos-ar.appspot.com/o/tavoloContenitore2%2FTavolo_contenitore_2_Previo01.jpg?alt=media&token=850ebe53-ca2b-4a12-87b8-427dda51671d'
    }
  ]
}
