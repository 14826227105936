import React, { useContext } from 'react'
// Motion
import { motion } from 'framer-motion'
// Context
import ObjectContext from 'context/ObjectContext'

export const SelectedObjectHeader = ({ selectedObject }) => {
  const { language } = useContext(ObjectContext)
  return (
    <div className='relative flex items-center h-36 w-full bg-gradient-to-r from-primary-dark to-primary-light'>
      <div className='relative px-6 flex items-center space-x-6 flex-1 z-10'>
        <div
          className='relative rounded-full h-16 w-16 xl:h-20 xl:w-20 shadow-xl bg-contain bg-gray-50'
        >
          <motion.img
            className='object-contain'
            key={selectedObject?.previewImage}
            src={selectedObject?.previewImage}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0.5 }}
          />
        </div>
        <div className='flex-1'>
          <h1 className='mb-0 text-lg xl:text-xl font-bold text-white'>{selectedObject?.name}</h1>
          <h2 className='text-sm xl:text-md font-light text-white'>{selectedObject?.subtitle}</h2>
        </div>
      </div>
      <div className='w-28 h-full flex flex-col items-center bg-black bg-opacity-10 border-l border-black border-opacity-10'>
        {selectedObject?.techSpecs && (
          <div
            onClick={() => window.open(selectedObject.techSpecs)}
            className='cursor-pointer transition duration-300 ease-in-out hover:bg-opacity-10 hover:bg-black w-full flex-1 flex items-center justify-center text-white text-opacity-80 hover:text-opacity-100'
          >
            <div className='text-xs uppercase tracking-widest font-semibold text-center'>{(language === 'es' && ('Ficha Técnica')) || (language === 'en' && ('Data Sheet')) || (language === 'it' && ('Scheda dati'))}</div>
          </div>
        )}
        {selectedObject?.gallery && (
          <div
            onClick={() => window.open(selectedObject.gallery)}
            className='cursor-pointer transition duration-300 ease-in-out hover:bg-opacity-20 hover:bg-black border-t border-black border-opacity-10 w-full flex-1 flex items-center justify-center text-white text-opacity-80 hover:text-opacity-100 '
          >
            <div className='text-xs uppercase tracking-widest font-semibold text-center'>{(language === 'es' && ('Ambiente')) || (language === 'en' && ('Environment')) || (language === 'it' && ('Atmosfera'))}</div>
          </div>
        )}
      </div>
    </div>
  )
}
