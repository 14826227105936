import React, { useState } from 'react'
// Context
import ObjectContext from 'context/ObjectContext'
// Config
import { OBJECTS } from 'config/objects'
// Router
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom'
// Pages
import { Main } from 'pages/Main'
import { OpenAR } from 'pages/OpenAR'

const App = () => {
  const [selectedObject, setSelectedObject] = useState(OBJECTS.tables[0])
  const [activeSection, setActiveSection] = useState('tables')
  const [loadingObject, setLoadingObject] = useState(false)
  const [language, setLanguage] = useState('es')

  return (
    <ObjectContext.Provider
      value={{
        selectedObject,
        setSelectedObject,
        activeSection,
        setActiveSection,
        loadingObject,
        setLoadingObject,
        language,
        setLanguage
      }}
    >
      <Router>
        <Switch>
          <Route path='/openAR/:type/:objectID'>
            <OpenAR />
          </Route>
          <Route path='/'>
            <Main />
          </Route>
        </Switch>
      </Router>
    </ObjectContext.Provider>
  )
}

export default App
