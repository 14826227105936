import React, { useContext, useState } from 'react'
// Context
import ObjectContext from 'context/ObjectContext'
// Config
import { OBJECTS } from 'config/objects'
// Framer
import { motion, AnimatePresence } from 'framer-motion'
// Components
import { FadeInImage } from 'components/FadeInImage'
import { SearchInput } from 'components/SearchInput'

export const ObjectSection = () => {
  const context = useContext(ObjectContext)
  const [filter, setFilter] = useState()

  return (
    <>
      <div className='pt-4 px-4'>
        <SearchInput
          onChange={value => setFilter(value)}
        />
      </div>
      <AnimatePresence exitBeforeEnter>
        <motion.div
          key={context.activeSection}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className='grid grid-cols-2 gap-6 gap-y-12 mt-1 mb-2 overflow-auto px-4'
        >
          {OBJECTS[context.activeSection]
            ?.filter(object => {
              if (filter) {
                const name = object?.name?.toUpperCase()
                const subtitle = object?.subtitle?.toUpperCase()
                return name?.includes(filter.toUpperCase()) || subtitle?.includes(filter.toUpperCase())
              } else {
                return true
              }
            })
            ?.map(object => {
              return (
                <div key={object.id} className='flex flex-col w-full'>
                  <div
                    onClick={() => {
                      if (!context.loadingObject) {
                        context.setSelectedObject(object)
                      }
                    }}
                    className='relative cursor-pointer w-full pt-[100%] h-0 rounded border-2 bg-gray-50 border-gray-100 bg-contain'
                    alt='preview'
                  >
                    <FadeInImage src={object.previewImage} />
                  </div>
                  <h4 className='font-bold mt-4 text-gray-600'>{object.name}</h4>
                  <h5 className='text-sm'>{object.subtitle}</h5>
                </div>
              )
            })}
        </motion.div>
      </AnimatePresence>
    </>
  )
}
