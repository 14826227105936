import React from 'react'
// Router
import {
  useParams
} from 'react-router-dom'
// Config
import { OBJECTS } from 'config/objects'
// Components
import { ButtonAR } from 'components/ButtonAR'

export const OpenAR = (props) => {
  const { objectID, type } = useParams()
  const objectInfo = OBJECTS[type]?.find(item => item.id === objectID)

  return (
    <div className='bg-gray-100 w-full h-screen flex justify-center items-center'>
      {objectInfo
        ? (
          <div className='text-center h-screen flex items-center justify-center flex-col text-gray-800'>
            <img
              className='rounded-xl shadow-lg mb-8 h-1/4'
              alt='ar' src={objectInfo?.previewImage}
            />
            <h1 className='text-2xl font-bold'>{objectInfo?.name}</h1>
            <h2 className='text-lg font-thin'>{objectInfo?.subtitle}</h2>
            <ButtonAR objectInfo={objectInfo} language={window.location.search.split('=')[1]} />
          </div>
          )
        : (
          <div>
            <span className='font-bold uppercase'>Error:</span> Objecto no encontrado
          </div>
          )}
    </div>
  )
}
