import React, { useContext, useState, useEffect } from 'react'
// Context
import ObjectContext from 'context/ObjectContext'
// Icons
import { TablesIcon } from 'components/icons/TablesIcon'
import { SinkIcon } from 'components/icons/SinkIcon'
// Motion
import { motion, AnimatePresence } from 'framer-motion'
import { SelectedObjectHeader } from 'components/SelectedObjectHeader'
// Section
import { ObjectSection } from 'components/sections/ObjectSection'
// Components
import LanguageSelector from 'components/LanguageSelector'

export const LeftMenu = () => {
  const context = useContext(ObjectContext)
  const [menuOpen, setMenuOpen] = useState(false)
  const { language } = context

  useEffect(() => {
    setMenuOpen(false)
  }, [context.selectedObject.id])

  const SECTIONS = {
    tables: {
      name:
        language === 'es' ? 'Mesas' : language === 'en' ? 'Tables' : 'Tabelle',
      icon: <TablesIcon />
    },
    sinks: {
      name:
        language === 'es' ? 'Lavabos' : language === 'en' ? 'Sinks' : 'Affonda',
      icon: <SinkIcon />
    }
  }

  return (
    <>
      <div className='w-96 hidden xl:flex left-menu h-screen flex-col bg-white shadow-xl'>
        <SelectedObjectHeader selectedObject={context?.selectedObject} />
        <div className='flex flex-1 w-full overflow-hidden'>
          <div className='w-24 bg-gray-100 flex flex-col h-full'>
            {Object.keys(SECTIONS).map(section => (
              <div
                key={section}
                className={`transition duration-500 ease-in-out ${
                  context.activeSection === section ? 'bg-white' : 'bg-gray-100'
                } w-full flex flex-col items-center justify-center h-20`}
              >
                <div
                  onClick={() => context.setActiveSection(section)}
                  className='flex flex-col items-center cursor-pointer'
                >
                  {React.cloneElement(SECTIONS[section].icon, {
                    active:
                      context.activeSection === section ? section : undefined,
                    className: 'w-8 h-8'
                  })}
                  <span
                    className={
                      context.activeSection === section
                        ? 'text-primary-dark font-bold'
                        : 'text-secondary'
                    }
                  >
                    {SECTIONS[section].name}
                  </span>
                </div>
              </div>
            ))}
            <LanguageSelector />
          </div>
          <div className='flex-1 flex flex-col'>
            <ObjectSection />
          </div>
        </div>
      </div>
      <div className='flex xl:hidden'>
        <span
          className='fixed translate z-50 t-0 r-0 w-12 h-12 flex items-center justify-center'
          onClick={() => setMenuOpen(!menuOpen)}
        >
          <svg
            className={`ham hamRotate ham1 ${menuOpen && 'active'}`}
            viewBox='0 0 100 100'
            width='80'
          >
            <path
              className='line top'
              stroke={`${menuOpen ? 'white' : '#235FA7'}`}
              d='m 30,33 h 40 c 0,0 9.044436,-0.654587 9.044436,-8.508902 0,-7.854315 -8.024349,-11.958003 -14.89975,-10.85914 -6.875401,1.098863 -13.637059,4.171617 -13.637059,16.368042 v 40'
            />
            <path
              stroke={`${menuOpen ? 'white' : '#235FA7'}`}
              className='line middle'
              d='m 30,50 h 40'
            />
            <path
              stroke={`${menuOpen ? 'white' : '#235FA7'}`}
              className='line bottom'
              d='m 30,67 h 40 c 12.796276,0 15.357889,-11.717785 15.357889,-26.851538 0,-15.133752 -4.786586,-27.274118 -16.667516,-27.274118 -11.88093,0 -18.499247,6.994427 -18.435284,17.125656 l 0.252538,40'
            />
          </svg>
        </span>
        <AnimatePresence>
          {menuOpen && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className='w-96 z-40 max-w-full absolute flex flex-1 h-screen flex-col bg-white shadow-xl'
            >
              <SelectedObjectHeader selectedObject={context?.selectedObject} />
              <div className='flex flex-1 w-full overflow-hidden'>
                <div className='w-24 bg-gray-100 flex flex-col h-full'>
                  {Object.keys(SECTIONS).map(section => (
                    <div
                      key={section}
                      className={`transition duration-500 ease-in-out ${
                        context.activeSection === section
                          ? 'bg-white'
                          : 'bg-gray-100'
                      } w-full flex flex-col items-center justify-center h-20`}
                    >
                      <div
                        onClick={() => context.setActiveSection(section)}
                        className='flex flex-col items-center cursor-pointer'
                      >
                        {React.cloneElement(SECTIONS[section].icon, {
                          active:
                            context.activeSection === section
                              ? section
                              : undefined,
                          className: 'w-10 h-10'
                        })}
                        <span
                          className={
                            context.activeSection === section
                              ? 'text-primary-dark font-bold'
                              : 'text-secondary'
                          }
                        >
                          {SECTIONS[section].name}
                        </span>
                      </div>
                    </div>
                  ))}
                  <LanguageSelector />
                </div>
                <div className='flex-1 flex flex-col'>
                  <ObjectSection />
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </>
  )
}
