import React from 'react'

export const TablesIcon = ({ active, className }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 464 464'
      className={`transform transition-all duration-500 fill-current ${
        active ? 'text-primary' : 'text-secondary'
      }  hover:scale-110  ${className}`}
      width='80'
      height='80'
    >
      <path
        xmlns='http://www.w3.org/2000/svg'
        d='M424 0l40 56H0L48 0zm0 0'
        fill='#2160a8'
        data-original='#ff9811'
      />
      <path
        xmlns='http://www.w3.org/2000/svg'
        d='M0 56h464v32H0zm0 0'
        fill='#00a0eb'
        data-original='#ffa733'
      />
      <path
        xmlns='http://www.w3.org/2000/svg'
        d='M72 88h24v184H72zm0 0M368 88h24v184h-24zm0 0'
        fill='#787680'
        data-original='#787680'
      />
      <path
        xmlns='http://www.w3.org/2000/svg'
        d='M16 88h32v240H16zm0 0M416 88h32v240h-32zm0 0'
        fill='#acabb1'
        data-original='#acabb1'
      />
      <path
        xmlns='http://www.w3.org/2000/svg'
        d='M72 88h24v8H72zm0 0M368 88h24v8h-24zm0 0'
        fill='#57565c'
        data-original='#57565c'
      />
      <g xmlns='http://www.w3.org/2000/svg' fill='#787680'>
        <path
          d='M16 88h32v16H16zm0 0M416 88h32v16h-32zm0 0'
          data-original='#787680'
        />
      </g>
    </svg>
  )
}
