import React from 'react'
// Components
import { Babylon } from 'components/Babylon'
import { LeftMenu } from 'components/LeftMenu'

export const Main = () => {
  return (
    <div className='flex bg-gray-400 w-full h-screen'>
      <LeftMenu />
      <Babylon />
    </div>
  )
}
